<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-customer-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Clienti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato" class="position-relative">
        <div class="mio_btn_float">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle"
              id="`sidebar-azioni`"
              @click="sidebarActionShow(campiform.customer.id, campiform.billing.company_name, campiform.billing.id_fic, campiform.billing.url_analisi_cliente_fic, userData.role)"
          >
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </div>

        <b-card>
          <b-card-body class="px-0 py-0">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                      <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Dettagli</span>
                  </template>
                  <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="4" lg="3">
                          <showdata field_name="Ragione Sociale" :field_value="campiform.billing.company_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Nome" :field_value="campiform.user.name" />
                        </b-col>
                        <b-col md="8" lg="3">
                          <showdata field_name="Cognome" :field_value="campiform.user.surname" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Email" :field_value="campiform.user.email" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Telefono" :field_value="campiform.user.phone" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Cellulare" :field_value="campiform.user.mobile_phone" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Reseller" :field_value="campiform.customer.reseller_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Assistente" :field_value="campiform.customer.assistant_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="HR" :field_value="campiform.customer.hr_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Valutazione" :field_value="campiform.customer.evalution_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Rating" :field_value="campiform.customer.rating" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Origine" :field_value="campiform.customer.source.text" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Sezione" :field_value="campiform.customer.section" />
                        </b-col>
                        <b-col cols="12"></b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Registrazione Lead" :field_value="formattazioneDataOra(campiform.customer.lead_registered_at, 'it')" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Conversione in cliente" :field_value="formattazioneDataOra(campiform.customer.created_at, 'it')" />
                        </b-col>
                        <b-col cols="12">
                          <showdata field_name="Sito web" :field_value="campiform.user.website" />
                        </b-col>
                        <b-col cols="12">
                          <showdata field_name="Note" :field_value="campiform.customer.note" />
                        </b-col>
                      </b-row>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="BriefcaseIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Fatturazione</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="4" lg="3">
                          <showdata field_name="Indirizzo" :field_value="campiform.billing.address" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Cap" :field_value="campiform.billing.zip" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Città" :field_value="campiform.billing.city" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Provincia" :field_value="campiform.billing.province_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Nazione" :field_value="campiform.billing.country_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Partita IVA" :field_value="campiform.billing.vat_number" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Codice fiscale" :field_value="campiform.billing.fiscal_code" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Pec" :field_value="campiform.billing.pec" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Codice SDI" :field_value="campiform.billing.dest_code" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Regime IVA" :field_value="campiform.billing.vat_scheme_name" />
                        </b-col>
                      </b-row>

                      <b-row v-if="campiform.openapi.scaricato === true">
                        <b-col md="4" lg="3">
                          <showdata field_name="Stato attività" :field_value="campiform.openapi.base.stato_attivita" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Rea" :field_value="campiform.openapi.base.rea" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Camera di commercio" :field_value="campiform.openapi.base.cciaa" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Codice ateco" :field_value="campiform.openapi.base.codice_ateco" />
                        </b-col>
                        <b-col md="8" lg="9">
                          <showdata field_name="Descrizione codice ateco" :field_value="campiform.openapi.base.descrizione_ateco" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Forma giuridica" :field_value="campiform.openapi.base.codice_natura_giuridica" />
                        </b-col>
                      </b-row>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Bilanci</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-card bg-variant="Default">
                        <div v-if="campiform.openapi.scaricato === true">
                          <b-table responsive="sm" :items="campiform.openapi.bilanci"/>
                        </div>
                        <div v-else>
                          <b-card border-variant="primary">
                            <b-card-text>Bilanci non presenti al momento</b-card-text>
                          </b-card>
                        </div>
                      </b-card>
                     
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Stato</span>
                    </template>
                    <b-card bg-variant="Default">
                        <b-row>
                          <b-col md="4" lg="3">
                            <showdata field_name="Data / ora ultimo accesso" field_value="da fare ..." />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="ID Stripe" field_value="da fare ... (con link dashboard stripe)" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Iscritto alla newsletter" field_value="da fare ..." />
                          </b-col>
                        </b-row>
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="FolderIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Logs</span>
                    </template>
                    <b-card bg-variant="Default">
                      <div v-if="campiform.customer.privacy" class="mb-3">
                        <h4 class="mb-1">Log firma informativa privacy GDPR/679/2016 - Lead</h4>
                        <b-row cols="2">
                          <b-col class="logs-colonna">
                            {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - Accettazione privacy<br />
                            {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - <span v-if="campiform.customer.termini">Accettazione termini</span><span v-else>Termini non accettati</span><br />
                            <span v-if="campiform.customer.user_agent != ''">{{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - {{ campiform.customer.user_agent }}</span><br />
                            <span v-if="campiform.customer.ip != ''">{{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - {{ campiform.customer.ip }}</span>
                          </b-col>
                          <b-col>
                            <showdata field_name="Data registrazione lead" :field_value="formattazioneDataOra(campiform.customer.created_at, 'it')" />
                          </b-col>
                        </b-row>
                      </div>

                      <b-row cols="2">
                        <b-col>
                          <h4 class="mb-1">Log firma contratto e firma informativa privacy GDPR/679/2016</h4>

                          <showdata field_name="Codice accettazione contratto:" field_value="123456" />

                          <div class="logs-colonna">
                            DD/MM/YYYY - HH:MM:SS - ecc. ecc.<br />
                            DD/MM/YYYY - HH:MM:SS - ...
                          </div>

                        </b-col>
                        <b-col>
                          <h4 class="mb-1">Log carte</h4>

                          <showdata field_name="Data conversione cliente / Iscritto il" :field_value="formattazioneDataOra(campiform.customer.converted_at, 'it')" />

                          <div class="logs-colonna">
                            DD/MM/YYYY - HH:MM:SS - ecc. ecc.<br />
                            DD/MM/YYYY - HH:MM:SS - ...
                          </div>

                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                      <feather-icon
                        icon="ClipboardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Commenti <small v-if="n_commenti > 0">({{ n_commenti }})</small></span>
                    </template>
                    <b-card bg-variant="Default">
                      <div class="text-right">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="mb-1"
                          @click="sidebarCommento('Nuovo Commento','','')"
                        >
                          <feather-icon icon="PlusIcon" /> Inserisci Commento
                        </b-button>
                      </div>

                      <div class="comments-list">
                        <vue-perfect-scrollbar
                          class="scroll-area"
                          :settings="perfectScrollbarSettings"
                        >
                        <ul class="list-unstyled">
                          <li v-for="riga in list_comments"
                            :key="riga.id"
                            class="d-flex justify-content-between align-items-center riga" 
                            @click="sidebarCommento('Modifica Commento',riga.id,riga.content)">
                            <div v-html="riga.content"></div>
                            <div class="text-muted dx">{{ riga.author }}<br /><span class="blockquote-footer">{{ formattazioneDataOra(riga.update_at, 'it') }}</span></div>
                          </li>
                        </ul>
                        
                        </vue-perfect-scrollbar>
                      </div>
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Assistenti</span>
                    </template>
                    <b-card bg-variant="Default">
                      da fare ...<br /><br />
                      <a href="https://docs.google.com/document/d/1Chqf9k3_lN9P2gGNl0WVxMLNwqnJr0quFN9abiyfp0M/edit#heading=h.x98ceiwyxzh2" target="_blank">documentazione su Google Doc</a>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Tracking</span>
                    </template>
                    <b-card bg-variant="Default">
                      da fare ... ci sarà tracciatura del lead generation by Google ads, bing, fb, ecc.<br /><br />
                      <a href="https://docs.google.com/document/d/1Chqf9k3_lN9P2gGNl0WVxMLNwqnJr0quFN9abiyfp0M/edit#heading=h.9nxybfda28uf" target="_blank">documentazione su Google Doc</a>
                    </b-card>
                  </b-tab>

                  <b-tab @click="onTabChangeAbbonamenti">
                    <template #title>
                        <feather-icon
                        icon="AwardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Abbonamenti</span>
                    </template>
                    <b-card bg-variant="Default">
                      <div class="text-right">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="mb-1"
                          @click="sidebarNuovaLicenza('Abbonamento')"
                        >
                          <feather-icon icon="PlusIcon" /> Nuovo Abbonamento
                        </b-button>
                      </div>
                      
                      <b-overlay :show="show_loader_licenze" rounded="sm">
                        <vue-good-table
                        :columns="columns_licenze"
                        :rows="rows_licenze"
                        :rtl="direction"
                        :search-options="{
                            enabled: true,
                            externalQuery: searchTerm }"
                        :pagination-options="{
                            enabled: true,
                            perPage:pageLength_licenze
                        }"
                        >
                        <template
                            slot="table-row"
                            slot-scope="props"
                        >

                          <!-- Column: Action -->
                            <span v-if="props.column.field === 'action'">
                            ...
                            </span>

                            <!-- Column: Price -->
                            <span v-else-if="props.column.field === 'price'">
                              {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                            </span>

                            <!-- Column: Inzio -->
                            <span v-else-if="props.column.field === 'subscription.created_at'">
                              {{ formattazioneDataOra(props.formattedRow[props.column.field], 'it') }}
                            </span>
                            <!-- Column: Fine -->
                            <span v-else-if="props.column.field === 'subscription.ends_at'">
                              {{ formattazioneDataOra(props.formattedRow[props.column.field], 'it') }}
                            </span>

                            <!-- Column: azioni -->
                            <span v-else-if="props.column.field === 'azioni'">
                              <div class="text-nowrap">
                                <feather-icon
                                  icon="ArrowRightIcon"
                                  class="cursor-pointer text-primary"
                                  size="21"
                                  v-b-tooltip.hover.v-primary
                                  title="Azioni"
                                  :id="`tabella-licenze-riga-${props.row.id}-sidebar-azioni`"
                                  @click="sidebarActionLicenze('Abbonamento',props.row.id, props.row.ref_code, props.row.status.name)"
                                />
                              </div>
                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >
                            <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap">
                                  Visualizzazione da 1 a
                                </span>
                                <b-form-select
                                v-model="pageLength_licenze"
                                :options="['3','5','10']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                                />
                                <span class="text-nowrap "> di {{ props.total }} righe </span>
                            </div>
                            <div>
                                <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength_licenze"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                                >
                                <template #prev-text>
                                    <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                    />
                                </template>
                                </b-pagination>
                            </div>
                            </div>
                        </template>
                        </vue-good-table>
                      </b-overlay>

                    </b-card>
                  </b-tab>

                  <b-tab @click="onTabChangeOneShot">
                    <template #title>
                        <feather-icon
                        icon="AwardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">OneShot</span>
                    </template>
                    <b-card bg-variant="Default">
                      <div class="text-right">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="mb-1"
                          @click="sidebarNuovaLicenza('OneShot')"
                        >
                          <feather-icon icon="PlusIcon" /> Nuovo One Shot
                        </b-button>
                      </div>

                      <b-overlay :show="show_loader_oneshot" rounded="sm">
                        <vue-good-table
                        :columns="columns_oneshot"
                        :rows="rows_oneshot"
                        :rtl="direction"
                        :search-options="{
                            enabled: true,
                            externalQuery: searchTerm }"
                        :pagination-options="{
                            enabled: true,
                            perPage:pageLength_oneshot
                        }"
                        >
                        <template
                            slot="table-row"
                            slot-scope="props"
                        >

                          <!-- Column: Action -->
                            <span v-if="props.column.field === 'action'">
                            ...
                            </span>

                            <!-- Column: Price -->
                            <span v-else-if="props.column.field === 'price'">
                              {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                            </span>

                            <!-- Column: azioni -->
                            <span v-else-if="props.column.field === 'azioni'">
                              <div class="text-nowrap">
                                <feather-icon
                                  icon="ArrowRightIcon"
                                  class="cursor-pointer text-primary"
                                  size="21"
                                  v-b-tooltip.hover.v-primary
                                  title="Azioni"
                                  :id="`tabella-oneshot-riga-${props.row.id}-sidebar-azioni`"
                                  @click="sidebarActionLicenze('OneShot', props.row.id, props.row.ref_code, props.row.status.name)"
                                />
                              </div>
                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >
                            <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap">
                                 Visualizzazione da 1 a
                                </span>
                                <b-form-select
                                v-model="pageLength_oneshot"
                                :options="['3','5','10']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                                />
                                <span class="text-nowrap "> di {{ props.total }} righe </span>
                            </div>
                            <div>
                                <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength_oneshot"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                                >
                                <template #prev-text>
                                    <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                    />
                                </template>
                                </b-pagination>
                            </div>
                            </div>
                        </template>
                        </vue-good-table>
                      </b-overlay>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Contratti</span>
                    </template>
                    <b-card bg-variant="Default">
                      da fare ... good-tables + sidebar per dettagli singolo contratto<br /><br />
                      api list: api/crm/contract/list?columnFilters={"license.customer.id": 1 }&perPage=10&page=1<br />
                      api show: api/crm/contract/card/1<br /><br />
                      <a href="https://docs.google.com/document/d/1Chqf9k3_lN9P2gGNl0WVxMLNwqnJr0quFN9abiyfp0M/edit#heading=h.rnhayoxtpmwa" target="_blank">documentazione su Google Doc</a>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="CheckCircleIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Pagamenti</span>
                    </template>
                    <b-card bg-variant="Default">
                      
                      api list: api/crm/license-payment-logs/list?columnFilters={"license.customer.id": 1 }&perPage=10&page=1<br />
                      api show: api/crm/license-payment-logs/card/1<br /><br />

                      <vue-good-table
                      :columns="columns_pagamenti"
                      :rows="rows_pagamenti"
                      :rtl="direction"
                      :search-options="{
                          enabled: true,
                          externalQuery: searchTerm }"
                      :pagination-options="{
                          enabled: true,
                          perPage:pageLength_pagamenti
                      }"

                      @on-row-click="onRowClick"
                      >
                      <template
                          slot="table-row"
                          slot-scope="props"
                      >

                         <!-- Column: Action -->
                          <span v-if="props.column.field === 'action'">
                           ...
                          </span>

                          <!-- Column: Common -->
                          <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                          </span>
                      </template>

                      <!-- pagination -->
                      <template
                          slot="pagination-bottom"
                          slot-scope="props"
                      >
                          <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                              <span class="text-nowrap">
                                Visualizzazione da 1 a
                              </span>
                              <b-form-select
                              v-model="pageLength_pagamenti"
                              :options="['3','5','10']"
                              class="mx-1"
                              @input="(value)=>props.perPageChanged({currentPerPage:value})"
                              />
                              <span class="text-nowrap "> di {{ props.total }} righe </span>
                          </div>
                          <div>
                              <b-pagination
                              :value="1"
                              :total-rows="props.total"
                              :per-page="pageLength_pagamenti"
                              first-number
                              last-number
                              align="right"
                              prev-class="prev-item"
                              next-class="next-item"
                              class="mt-1 mb-0"
                              @input="(value)=>props.pageChanged({currentPage:value})"
                              >
                              <template #prev-text>
                                  <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                  />
                              </template>
                              <template #next-text>
                                  <feather-icon
                                  icon="ChevronRightIcon"
                                  size="18"
                                  />
                              </template>
                              </b-pagination>
                          </div>
                          </div>
                      </template>
                      </vue-good-table>

                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <hr />
                      <a href="https://docs.google.com/document/d/1Chqf9k3_lN9P2gGNl0WVxMLNwqnJr0quFN9abiyfp0M/edit#heading=h.7r95teci4uzq" target="_blank">documentazione su Google Doc</a>
                      
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Fatture</span>
                    </template>
                    <b-card bg-variant="Default">
                      <vue-good-table
                      :columns="columns_fatture"
                      :rows="rows_fatture"
                      :rtl="direction"
                      :search-options="{
                          enabled: true,
                          externalQuery: searchTerm }"
                      :pagination-options="{
                          enabled: true,
                          perPage:pageLength_fatture
                      }"

                      @on-row-click="onRowClick"
                      >
                      <template
                          slot="table-row"
                          slot-scope="props"
                      >

                         <!-- Column: Action -->
                          <span v-if="props.column.field === 'action'">
                           ...
                          </span>

                          <!-- Column: Common -->
                          <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                          </span>
                      </template>

                      <!-- pagination -->
                      <template
                          slot="pagination-bottom"
                          slot-scope="props"
                      >
                          <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                              <span class="text-nowrap">
                                Visualizzazione da 1 a
                              </span>
                              <b-form-select
                              v-model="pageLength_fatture"
                              :options="['3','5','10']"
                              class="mx-1"
                              @input="(value)=>props.perPageChanged({currentPerPage:value})"
                              />
                              <span class="text-nowrap "> di {{ props.total }} righe </span>
                          </div>
                          <div>
                              <b-pagination
                              :value="1"
                              :total-rows="props.total"
                              :per-page="pageLength_fatture"
                              first-number
                              last-number
                              align="right"
                              prev-class="prev-item"
                              next-class="next-item"
                              class="mt-1 mb-0"
                              @input="(value)=>props.pageChanged({currentPage:value})"
                              >
                              <template #prev-text>
                                  <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                  />
                              </template>
                              <template #next-text>
                                  <feather-icon
                                  icon="ChevronRightIcon"
                                  size="18"
                                  />
                              </template>
                              </b-pagination>
                          </div>
                          </div>
                      </template>
                      </vue-good-table>
                      
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <hr />
                      <a href="https://docs.google.com/document/d/1Chqf9k3_lN9P2gGNl0WVxMLNwqnJr0quFN9abiyfp0M/edit#heading=h.f1tnvgd26nw3" target="_blank">documentazione su Google Doc</a>
                    </b-card>
                  </b-tab>

              </b-tabs>
          </b-card-body>
        </b-card>
    </div>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.company_name"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-customers-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
            @click="cancellaRiga(infoSideBar.id)"
          >
            <feather-icon icon="Trash2Icon" /> Cancella
          </b-button>

          <div v-if="infoSideBar.ruoloUtente === 'SuperAdmin'" class="text-center">
            <div class="divider">
              <div class="divider-text text-primary fw-bolder mt-2">FATTURE IN CLOUD</div>
            </div>

            <h5 class="mb-1">Queste informazioni sono visibili solo a chi ha i privilegi di {{ infoSideBar.ruoloUtente }}</h5>
            <div v-if="infoSideBar.id_fic !== null">
              <b-row>
                <b-col sm="12">
                  <showdata field_name="ID cliente in Fatture in Cloud" :field_value="infoSideBar.id_fic" />
                </b-col>
                <b-col sm="12">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    target="_blank"
                    block
                    :href="infoSideBar.url_analisi_cliente_fic"
                  >
                    <feather-icon icon="ExternalLinkIcon" /> Analisi cliente su Fatture in Cloud
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col sm="12" class="mb-1">
                  I dati su Fatture in Cloud non sono aggiornati rispetto alla scheda Cliente?
                </b-col>
                <b-col sm="12">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning"
                    :disabled="action_press"
                    block
                    @click="forzaSyncFIC(infoSideBar.id)"
                  >
                    <div v-if="action_press">Sincronizzazione in corso <b-spinner small class="ml-1" /></div>
                    <div v-else><feather-icon icon="UploadCloudIcon" /> Forza sincronizzazione</div> 
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <b-card v-else border-variant="danger">
              <h4 class="mt-0 mb-0 text-danger">Questa anagrafica cliente non risulta sincronizzata con Fatture in Cloud</h4>
              <p class="mb-0">Ti preghiamo di segnalare il problema all'assistenza tecnica AgentScout, grazie</p>
            </b-card>
          </div>
        </div>

      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_commenti"
      id="sidebar-commenti"
      bg-variant="white"
      :title="SideBarCommenti.tipo_operazione"
      :visible="sidebar_visibile"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-form-group
                label="Commento"
                label-for="task-description"
              >
                <validation-provider
                #default="{ errors }"
                name="Commento"
                rules="required"
                >
                  <quill-editor
                    id="quil-content"
                    v-model="campiform_commenti.content"
                    :options="editorOption"
                    class="border-bottom-0"
                    style="height: 250px;"
                  />
                  <div
                    id="quill-toolbar"
                    class="d-flex justify-content-end border-top-0"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                  </div>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="mt-2">
                <b-button
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="validationFormCommento()"
                  >
                  Salva
                </b-button>
                <b-button
                  v-if="SideBarCommenti.tipo_operazione == 'Modifica Commento'"
                  variant="outline-danger" 
                  class="ml-2"
                  type="submit"
                  @click.prevent="CancellaCommento(SideBarCommenti.id_commento)"
                  >
                  Cancella
                </b-button>
              </div>
            </b-form>
          </validation-observer>

        </div>
      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_nuova_licenza"
      id="sidebar-nuova-licenza"
      bg-variant="white"
      width="450px"
      title="Nuova Licenza"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <div class="text-center my-3" v-if="Caricato_sidebar_nuova_licenza == false">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              label="loading"
            />
          </div>
          <div v-else>
            <b-form-radio-group
              v-model="nuova_licenza_value"
              :options="nuova_licenza_options"
              :state="state_select_nuova_licenza"
              class="selezione_licenza"
              name="radio-validation"
              stacked
              html
            >
              <b-form-valid-feedback class="text-right my-2" :state="state_select_nuova_licenza">
                <b-button
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="attivaNuovaLicenza(infoSideBarActionNuovaLicenza.tipo_licenza)"
                  >
                  Inserisci nuova licenza
                </b-button>
              </b-form-valid-feedback>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_azioni_licenze"
      id="sidebar-right-azioni-licenze"
      bg-variant="white"
      :title="infoSideBarActionLicenze.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Ref:</span> {{ infoSideBarActionLicenze.ref_code }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">status:</span> {{ infoSideBarActionLicenze.status_name }}
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <div v-if="infoSideBarActionLicenze.status_name == 'Creata'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="ConfermaStatus(infoSideBarActionLicenze.tipo_licenza,infoSideBarActionLicenze.id)"
          >
            <feather-icon icon="RefreshCwIcon" /> Conferma Licenza
          </b-button>
          </div>
          <div v-else>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-licenze-show', params: { id_riga: infoSideBarActionLicenze.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza Licenza
          </b-button>
          </div>

        </div>

      </div>
    </b-sidebar>
      
  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BFormSelect, BButton, BSidebar, VBToggle, BBadge, BFormTextarea, BPagination, BFormRadioGroup, BFormValidFeedback, BOverlay, VBTooltip, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required
} from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { logoutGeneral } from "@core/components/logout/logout.vue"

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BFormGroup,
    BForm, 
    BFormSelect, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BFormTextarea,
    BPagination, 
    BFormRadioGroup,
    BFormValidFeedback,
    BOverlay,
    BTable, 

    showdata,
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
    localize,
    it,
    quillEditor,
    VueGoodTable,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 
    logoutGeneral,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      prezzoFormattato,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      userData: {},
      perfectScrollbarSettings: '',
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'scrivi commento ...',
      },
      id_italina: '1',
      campiform: {
        customer: {
          id: '',
          user_id: '',
          reseller_id: '',
          reseller_name: '',
          assistant_id: '',
          assistant_name: '',
          hr_id: '',
          hr_name: '',
          evaluation_id: '',
          evaluation_name: '',
          rating: '',
          created_at: '',
          source: [],
          note: '',
          lead_registered_at: '',
          section: ''
        },
        user: {
          email: '', 
          name: '', 
          surname: '',
          mobile_phone: '',
          phone: '',
          website: '',
        },
        billing: {
          country_id: '',
          country_name: '',
          province_id: '',
          province_name: '',
          address: '',
          zip: '',
          city: '',
          company_name: '',
          vat_number: '',
          fiscal_code: '',
          pec: '',
          dest_code: '',
          vat_scheme_id: null,
          vat_scheme_name: '',
          id_fic: '',
          url_analisi_cliente_fic: '',
        },
        openapi: {
          scaricato: false,
        },
      },
      list_comments: { },
      n_commenti: 0,
      campiform_commenti: {
        content: '',
        id_commento: '',
        tipo_operazione: 'new'
      },
      campiform_commenti_start: {
        content: '',
        tipo_operazione: 'new'
      },
      sidebar_visibile: false,
      options_usercrm: [ ],
      options_evalution: [ ],
      options: [ ],
      options_province: [ ],
      options_regine_iva: [ ],
      area_select_provincia: true,
      Caricato: false,
      errore_caricamento: false,
      infoSideBar: {
        id: '',
        company_name: '', 
        id_fic: '', 
        url_analisi_cliente_fic: '', 
        ruoloUtente: '',
      },
      SideBarCommenti: { 
        tipo_operazione: '', 
        id_card: '', 
        id_commento: '' 
      },
      infoSideBarActionLicenze: {
        tipo_licenza: '',
        titolo: '',
        id: '',
        ref_code: '',
        status_name: '',
      },
      required,

      action_press: false,

      dir: false,

      /* goodtable per Abbonamenti */
      show_loader_licenze: true, 
      pageLength_licenze: 10,
      columns_licenze: [
        {
          label: 'ID Licenza',
          field: 'ref_code',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Stato',
          field: 'status.name',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Inizio',
          field: 'subscription.created_at',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Fine',
          field: 'subscription.ends_at',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Stato (Stripe)',
          field: 'subscription.stripe_status',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Tipo',
          field: 'type',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Prezzo',
          field: 'price',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows_licenze: [],
      
      serverParams_Licenze: {
        columnFilters: {
          "type":"Abbonamento",
          "customer_id": router.currentRoute.params.id_riga,
          "deleted_at": {"operator": "=", "value": null }

        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      /* goodtable per Abbonamenti */

      /* goodtable per OneShot */
      show_loader_oneshot: true, 
      pageLength_oneshot: 10,
      columns_oneshot: [
        {
          label: 'ID Licenza',
          field: 'ref_code',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Stato',
          field: 'status.name',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Inizio',
          field: 'oneshot.started_at',
          formatFn: function (value) {
              return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
          },
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Fine',
          field: 'oneshot.expires_at',
          formatFn: function (value) {
              return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
          },
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Stato (Stripe)',
          field: 'status.name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Tipo',
          field: 'type',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Prezzo',
          field: 'price',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows_oneshot: [],
      
      serverParams_oneshot: {
        columnFilters: {
          "type":"OneShot",
          "customer_id": router.currentRoute.params.id_riga,
          "deleted_at": {"operator": "=", "value": null }

        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      /* goodtable per OneShot */

      /* goodtable per Pagamenti */
      pageLength_pagamenti: 10,
      columns_pagamenti: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Licenza',
          field: 'licenza',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Stato',
          field: 'stato',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Importo',
          field: 'importo',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Fattura Xml',
          field: 'fattura_xml',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Data Fattura',
          field: 'data_fattura',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Bonifico',
          field: 'bonifico',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Data Bonifico',
          field: 'data_bonifico',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'ID Bonifico',
          field: 'id_bonifico',
          filterOptions: {
            enabled: true,
          },
        },
      ],
      rows_pagamenti: [],
      /* goodtable per Pagamenti */

      /* goodtable per Fatture */
      pageLength_fatture: 10,
      columns_fatture: [
        {
          label: 'Numero',
          field: 'numero',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Data',
          field: 'data',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Importo',
          field: 'importo',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Stato',
          field: 'stato',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Incassato',
          field: 'incassato',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Bonifico',
          field: 'bonifico',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Data Bonifico',
          field: 'data_bonifico',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'ID Bonifico',
          field: 'id_bonifico',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Licenza',
          field: 'licenza',
          filterOptions: {
            enabled: true,
          },
        },
      ],
      rows_fatture: [],
      /* goodtable per Fatture */

      searchTerm: '',
      infoSideBar: {
        id: '',
        fullName: '',
        avatar: '',
      },

      Caricato_sidebar_nuova_licenza: false,
      nuova_licenza_value: null,
      nuova_licenza_options: [],
      infoSideBarActionNuovaLicenza: {
        tipo_licenza: '',
      }
      
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    state_select_nuova_licenza() {
      return Boolean(this.nuova_licenza_value)
    },
  },
  created() {
    //controllo tipo di employee (es. Admin o SuperAdmin o ...)
    this.userData = JSON.parse(localStorage.getItem('userData'))

    /*dati fake per goodtable per pagamenti */
    this.$http.get('/test-clienti-show/pagamenti')
      .then(res => { 
        this.rows_pagamenti = res.data 
      })
    /*dati fake per goodtable per pagamenti */

    /*dati fake per goodtable per fatture */
    this.$http.get('/test-clienti-show/fatture')
      .then(res => { 
        this.rows_fatture = res.data 
      })
    /*dati fake per goodtable per fatture */

    const countryPromise = this.$http.get('api/fs/country/list')
    const provincePromise = this.$http.get('api/fs/province/list')
    const userCrmPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee"}&page=1&perPage=100&status=active')
    const evaluationPromise = this.$http.get('api/fs/lead/evaluation/list')
    const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')
    const infoCustomerPromise = this.$http.get('api/crm/customer/card/'+router.currentRoute.params.id_riga)
    const infoBillingPromise = this.$http.get('api/crm/billing/customer/card/'+router.currentRoute.params.id_riga)
    const CommentsPromise = this.$http.get('api/crm/comments/customer/list/'+router.currentRoute.params.id_riga+'?sort[]={"field":"created_at","type":"desc"}&page=1&perPage=100')

    Promise.all([countryPromise, provincePromise, userCrmPromise, evaluationPromise, vatSchemePromise, infoCustomerPromise, infoBillingPromise, CommentsPromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista nazioni
        this.options = response[0].data.reply

        if(response[1].data.statusCode===200){
          //caricamento lista province italiane
          this.options_province = response[1].data.reply

          if(response[2].data.statusCode===200){
            //caricamento lista "user Crm" (sia come "reseller" sia come "assistent")
            this.options_usercrm = response[2].data.reply.data
                
            if(response[3].data.statusCode===200){
              //caricamento lista evalutation
              this.options_evalution = response[3].data.reply

              if(response[4].data.statusCode===200){
                //caricamento lista regime iva
                this.options_regine_iva = response[4].data.reply  
                
                if(response[5].data.statusCode===200){
                  //dati customer
                  this.campiform.customer = response[5].data.reply.customerData

                  if(response[6].data.statusCode===200){
                    //nome reseller
                    var id_reseller = this.campiform.customer.reseller_id
                    var reseller = this.options_usercrm.filter(function(elem){
                        if(elem.id == id_reseller){
                        return elem.name
                        }
                    })
                    this.campiform.customer.reseller_name = reseller[0].name+' '+reseller[0].surname

                    //nome assistent
                    var id_assistant = this.campiform.customer.assistant_id
                    var assistent = this.options_usercrm.filter(function(elem){
                        if(elem.id == id_assistant){
                        return elem.name
                        }
                    })
                    this.campiform.customer.assistant_name = assistent[0].name+' '+assistent[0].surname

                    //nome hr
                    var id_hr = this.campiform.customer.hr_id
                    var hr = this.options_usercrm.filter(function(elem){
                        if(elem.id == id_hr){
                        return elem.name
                        }
                    })
                    if(hr[0] === undefined){
                      this.campiform.customer.hr_name = 'Non assegnato'
                    } else {
                      this.campiform.customer.hr_name = hr[0].name+' '+hr[0].surname
                    }

                    //nome evalution
                    var id_evalution = this.campiform.customer.evaluation_id
                    var evalution = this.options_evalution.filter(function(elem){
                        if(elem.value == id_evalution){
                        return elem.text
                        }
                    })
                    
                    if(evalution[0] === undefined){
                      this.campiform.customer.evalution_name = 'N.D.'
                    } else {
                      this.campiform.customer.evalution_name = evalution[0].text
                    }
                      
                    //caricamento dati billing
                    this.campiform.billing = response[6].data.reply.billingData

                    //url per link alla Analisi cliente su FIC
                    this.campiform.billing.url_analisi_cliente_fic = 'https://secure.fattureincloud.it/clients-analysis-'+this.campiform.billing.id_fic

                    //nazione
                    var id_nazione = this.campiform.billing.country_id
                    var nazione = this.options.filter(function(elem){
                        if(elem.value == id_nazione){
                        return elem.text
                        }
                    })
                    this.campiform.billing.country_name = nazione[0].text

                    //provincia
                    var id_provincia = this.campiform.billing.province_id
                    var provincia = this.options_province.filter(function(elem){
                        if(elem.value == id_provincia){
                          return elem.text
                        }
                    })
                    this.campiform.billing.province_name = provincia[0].text

                    //regime iva
                    var id_regime_iva = this.campiform.billing.vat_scheme_id
                    var regime_iva = this.options_regine_iva.filter(function(elem){
                        if(elem.value == id_regime_iva){
                          return elem.text
                        }
                    })
                    this.campiform.billing.vat_scheme_name = regime_iva[0].text

                    this.$http.get('api/crm/billing/financialstatements/'+this.campiform.billing.id).then(response_bilancio => {
                      if((response_bilancio.data.statusCode>=200) && (response_bilancio.data.statusCode<=299)){
                        //caricamento dati billing
                        this.campiform.openapi = response_bilancio.data.reply

                        console.log(this.campiform.openapi)
                      } else {
                        //risposta negativa (errore sul server)
                      }
                    }).catch(e => {
                      console.log(e);
                    });

                    if(response[7].data.statusCode===200){
                      //caricamento comment
                      this.list_comments = response[7].data.reply.data
                      this.n_commenti = response[7].data.reply.meta.total

                      //estrazione dati User (sicrono)
                      this.$http.get('api/crm/user/customer/card/'+this.campiform.customer.id).then(response_user => {
                        if((response_user.data.statusCode>=200) && (response_user.data.statusCode<=299)){
                          //risposta positiva
                          this.campiform.user = response_user.data.reply.userData
                          
                          this.Caricato = true;
                          this.errore_caricamento = false;
                        } else {
                          //risposta negativa (errore sul server)
                          
                          this.Caricato = false;
                          this.errore_caricamento = true;
                        }
                      }).catch(e => {
                        console.log(e);
                      });
                    } else {
                    this.Caricato = false;
                    this.errore_caricamento = true;
                    }
                  } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                  }                   
                } else {
                this.Caricato = false;
                this.errore_caricamento = true;
                }
              } else {
              this.Caricato = false;
              this.errore_caricamento = true;
              }
            } else {
            this.Caricato = false;
            this.errore_caricamento = true;
            }

            
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
    
  },
  methods: {
    onTabChangeAbbonamenti() {
      /* caricare dati Abbonamento */
      this.caricareLicenze("Abbonamento");
    },
    onTabChangeOneShot(){
      /* caricare dati One Shot */
      this.caricareLicenze("OneShot");

    },

    forzaSyncFIC(id_customer){
      //console.log("richiesta sincro per ID_CUSTOMER -> "+id_customer)

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la sincronizzazione dei dati verso Fatture in Cloud",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.action_press = true

          this.$http.post('api/crm/customer/sync_fic/'+id_customer)
          .then(response_sync => {
            if((response_sync.data.statusCode>=200) && (response_sync.data.statusCode<=299)){
              //risposta positiva
              this.$swal({
                  icon: 'success',
                  title: 'Anagrafica cliente aggiornata correttamente su Fatture in Cloud',
                  confirmButtonText: 'chiudi',
                  customClass: {
                  confirmButton: 'btn btn-success',
                  },
              })
              
              this.action_press = false
            
            } else {
              //risposta negativa (errore sul server)
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore nella sincronizzazione dai dati su Fatture in Cloud',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
                
              this.action_press = false
            }
          }).catch(e => {
            console.log(e);
          });

        }
      })

    },

    sidebarActionShow(id, company_name, id_fic, url_analisi_cliente_fic, ruoloUtente) {
      this.infoSideBar = { id: id, company_name: company_name, id_fic: id_fic, url_analisi_cliente_fic: url_analisi_cliente_fic, ruoloUtente: ruoloUtente, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del Cliente?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/customer/delete/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                    //risposta positiva
                    this.$router.replace('/crm/customers')
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: response.data.reply.reply,
                                //text: 'operazione effettuata correttamente',
                                confirmButtonText: 'chiudi',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        })
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
    sidebarCommento(tipo_operazione, id_commento, testo_commento){
      console.log("id_card -> "+router.currentRoute.params.id_riga+"\nid_commento -> "+id_commento)
      this.SideBarCommenti = { tipo_operazione: tipo_operazione, id_card: router.currentRoute.params.id_riga, id_commento: id_commento };

      if( tipo_operazione == 'Nuovo Commento' ){
        this.campiform_commenti.tipo_operazione = 'new'
        this.campiform_commenti.content = ''
        this.campiform_commenti.id_commento = ''
      } else {
        this.campiform_commenti.tipo_operazione = 'edit'
        this.campiform_commenti.content = testo_commento
        this.campiform_commenti.id_commento = id_commento
      }

      this.$root.$emit('bv::toggle::collapse', 'sidebar-commenti')
    },
    caricareLicenze(tipo = 'Abbonamento'){
      //console.log("---- carico api licenze -------")
      this.show_loader_licenze = true;

      //console.log("tipo -> "+tipo)

      //cambio tipo di licenza richiesta
      if(tipo == 'Abbonamento'){
        this.serverParams_Licenze.columnFilters.type = "Abbonamento";

        this.$http.get('api/crm/license/list', {
          params: this.serverParams_Licenze 
          }).then(response => { 
              if(response.data.statusCode == 200){
                //ok
                this.rows_licenze = response.data.reply.data
                this.show_loader_licenze = false; 
                
              } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.rows_licenze = []
                this.show_loader_licenze = false; 

                this.$swal({
                  title: 'Errore caricamento Licenze Abbonamento',
                  text: 'codice errore: '+response.data.reply,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              } else {
                //tutto gli altri possibili errori
                this.rows_licenze = []
                this.show_loader_licenze = false;
                
                this.$swal({
                  title: 'Errore Generico caricamento Licenze Abbonamento',
                  text: 'codice errore: '+response.data.statusCode,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              }
          
          })

      } else {
        //one shot
        this.serverParams_oneshot.columnFilters.type = "OneShot";

        this.$http.get('api/crm/license/list', {
          params: this.serverParams_oneshot 
          }).then(response => { 
              if(response.data.statusCode == 200){
                //ok
                this.rows_oneshot = response.data.reply.data
                this.show_loader_oneshot = false; 
                
              } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.rows_oneshot = []
                this.show_loader_oneshot = false; 

                this.$swal({
                  title: 'Errore caricamento Licenze One Shot',
                  text: 'codice errore: '+response.data.reply,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              } else {
                //tutto gli altri possibili errori
                this.rows_oneshot = []
                this.show_loader_oneshot = false;
                
                this.$swal({
                  title: 'Errore Generico caricamento Licenze One Shot',
                  text: 'codice errore: '+response.data.statusCode,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              }
          
          })
      }
    },
    validationFormCommento(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line

          //c'è da aggregare altre informazioni da inviare all'api per creare l'utente ???
          console.log(this.campiform_commenti)

          let url_api = '';
          if( this.campiform_commenti.tipo_operazione == 'new' ){
            url_api+='api/crm/comments/customer/card/'+router.currentRoute.params.id_riga
          } else {
            url_api+='api/crm/comments/customer/card/'+router.currentRoute.params.id_riga+'/'+this.campiform_commenti.id_commento;
          }

          this.$http.post(url_api, 
            this.campiform_commenti 
            ).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.commentData.id > 0){
                  //salvataggio effettuato + aggiorna lista commenti (ultimo salvato primo della lista)
                  if( this.campiform_commenti.tipo_operazione == 'new' ){
                    //aggiungi alla lista
                    this.list_comments.unshift(response.data.reply.commentData)
                    
                    //aggiorna conteggio commenti
                    //console.log("conta -> "+this.list_comments.length)
                    this.n_commenti = this.list_comments.length

                  } else {
                    //modifica -> aggiorna relativa voce della lista
                    this.list_comments = this.list_comments.map(object => {
                      if (object.id == response.data.reply.commentData.id) {
                        return {...object,
                          content: response.data.reply.commentData.content,
                          update_at: response.data.reply.commentData.update_at,
                          author: response.data.reply.commentData.author};
                      }
                      return object;
                    });
                  }

                  //chiudo finestra ...
                  this.$refs.sidebar_commenti.hide();
                  //azzero form commento
                  this.campiform_commenti = this.campiform_commenti_start

                  //reset validation form
                  this.$refs.simpleRules.reset();

                  this.$swal({
                      icon: 'success',
                      title: 'Nuovo commento salvato correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },
    CancellaCommento(id_riga_commento){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del commento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/comments/customer/delete/'+router.currentRoute.params.id_riga+'/'+id_riga_commento)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_commenti.hide();

                  //rimuovi il commento eliminato dalla lista
                  this.list_comments = this.list_comments.filter(riga => riga.id != id_riga_commento);

                  //aggiorna conteggio commenti
                  this.n_commenti = this.list_comments.length
                    
                } else {
                 //chiudi sidebar
                 this.$refs.sidebar_commenti.hide();

                 //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    sidebarActionLicenze(tipo, id, ref_code, status_name) {
      this.infoSideBarActionLicenze = { 
        tipo_licenza: tipo,
        id: id,
        titolo: 'ID Licenza '+ref_code,
        ref_code: ref_code,
        status_name: status_name,
      };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-azioni-licenze')
    },
    ConfermaStatus(tipo_licenza,id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere alla conferma della licenza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/license/confirm/'+id_riga)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva

                  //aggiorna tabella licenze
                  this.caricareLicenze(tipo_licenza);

                  //azzero selezione licenza fatta (per evitare che nella prossima lista ci sia già una voce selezionata di default)
                  this.nuova_licenza_value = null;

                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni_licenze.hide();                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    
    onRowClick(params) {
      // params.row - row object 
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
      console.log(params.row)
      console.log(params.event)
    },
    elabPrezzoStripe(prezzo){
      let length = prezzo.length;
      let breack = (length - 2);
      let prezzo_intero = prezzo.slice(0, breack);
      let prezzo_decimali = prezzo.slice(breack, 2);
      let prezzo_elab = prezzo_intero+"."+prezzo_decimali;
      let val = (prezzo_elab/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    sidebarNuovaLicenza(tipo) {
      let route = '';
      if(tipo == 'Abbonamento'){
        route = 'subscription/';
      } else {
        route = 'oneshot/';
      }

      this.infoSideBarActionNuovaLicenza = { 
        tipo_licenza: tipo
      };

      //reimposta loader per caricamento licenze da stripe
      this.Caricato_sidebar_nuova_licenza = false

      //cancella lista licenza di stripe eventualmente caricate in precedenza
      this.nuova_licenza_options = [];

      //evento apri sidebar
      this.$root.$emit('bv::toggle::collapse', 'sidebar-nuova-licenza')

      this.$http.get('api/crm/stripe/product/'+route+'list').then(response => { 
        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          
          console.log("dati prodotti su Stripe")
          console.log(response.data.reply)
          console.log("lunghezza array -> "+response.data.reply.length)
          
          //caricamento lista licenze per corretta visualizzazione
          let u = 0;
          while (u < response.data.reply.length) {
            //elaborazione del prezzo da visualizzare
            let prezzo_ok = this.elabPrezzoStripe(response.data.reply[u].default_price.unit_amount_decimal);
            //fine elaborazione del prezzo da visualizzare

            let tipo = '';
            if(response.data.reply[u].default_price.type == 'one_time'){
              tipo = 'One Shot';
            } else {
              tipo = 'Abbonamento';
            }

            let option = '';
            
            if(response.data.reply[u].metadata.duration_days != undefined){
              option = '<strong>'+response.data.reply[u].name+'</strong><div class="d-flex justify-content-between"><div><i>'+tipo+' - '+response.data.reply[u].metadata.duration_days+' giorni</i></div><div>'+prezzo_ok+'</div></div>';
            } else {
              option = '<strong>'+response.data.reply[u].name+'</strong><div class="d-flex justify-content-between"><div><i>'+tipo+'</i></div><div>'+prezzo_ok+'</div></div>';
            }
            
            this.nuova_licenza_options[u] = { 
              html: option,
              value: response.data.reply[u].default_price.id
            },            
            u++;
          }

          //console.log(this.nuova_licenza_options)

          this.Caricato_sidebar_nuova_licenza = true

        } else {
          //risposta negativa (errore sul server)
          if(response.data.reply.message != ''){
            this.$swal({
                icon: 'warning',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          } else {
            this.$swal({
                icon: 'error',
                title: 'Impossibile recuperare i dati dei prodotti su Stripe',
                text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          }

          this.Caricato_sidebar_nuova_licenza = false
        }

      }).catch(e => {
        console.log(e);
      });
    },
    attivaNuovaLicenza(tipo){
      /*
      console.log("attivaNuovaLicenza ------------")
      console.log("id_licenza selezionata -> "+this.nuova_licenza_value)
      console.log("va inviata all'api come variabile 'price_id' + il valore di customer_id come 'customer_id'")
      */

      this.$http.post('api/crm/license/card', {
        price_id: this.nuova_licenza_value, 
        customer_id: router.currentRoute.params.id_riga
      }).then(response => { 
        //chiudi sidebar
        this.$refs.sidebar_nuova_licenza.hide();

        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          //salvataggio effettuato
          console.log(response.data)

          /* ricarica dati reali licenze */
          this.caricareLicenze(tipo);

          this.$swal({
              icon: 'success',
              title: 'Licenza inserita correttamente',
              //text: 'operazione effettuata correttamente',
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
          })
          
        } else {
          //risposta negativa (errore sul server)
          if(response.data.reply.message != ''){
            this.$swal({
              icon: 'warning',
              title: 'Si è verificato un errore',
              text: response.data.reply.message,
              customClass: {
              confirmButton: 'btn btn-outline-primary',
              },
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Impossibile attivare la nuova licenza per questo cliente',
              text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
              customClass: {
              confirmButton: 'btn btn-outline-primary',
              },
            })
          }
        }
      }).catch(e => {
        this.$swal({
          icon: 'error',
          title: 'Impossibile salvare la nuova licenza per questo cliente',
          text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
          customClass: {
          confirmButton: 'btn btn-outline-primary',
          },
        })
      });

    }
  },
  formattazioneDataOra(created_at,lang) {
    if (created_at) {
      return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
    } else {
      return '---'
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  
  .mio_btn_float{
    display: block;
    position: absolute;
    top: -55px;
    right: 2px;
    z-index: 10;
  }

  .comments-list .scroll-area {
    position: relative;
    margin: auto;
    padding: 10px;
    width: 100%;
    height: 450px;
    border: 1px solid #eee;
  }
  .comments-list .scroll-area ul li.riga{
    border-bottom: 1px solid #eee;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.2s, border-color 0s;
    position: relative;
  }
  .comments-list .scroll-area ul li.riga:hover{
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
  }
  .comments-list .scroll-area ul li.riga:last-child{
    border-bottom: none;
  }
  .comments-list .scroll-area ul li.riga .dx{
    min-width: 150px;
    margin-left: 25px;
    text-align: right;
  }

  .selezione_licenza{
    margin-top: 25px;
  }
  .selezione_licenza .custom-radio.custom-control{
    margin-bottom: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid #ebe9f1;
  }
  .selezione_licenza .custom-radio.custom-control .custom-control-label{
    width: 100%;
  }
</style> 
